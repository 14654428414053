<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card v-if="guildSettings">
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Настройки гильдии</h4>
          </div>
        </md-card-header>

        <ValidationObserver ref="updateGuildSettingsForm" :slim="true">
          <md-card-content>
            <div class="md-layout md-gutter">
              <template v-for="(value, name, index) in guildSettings">
                <div
                  :key="`guild-setting-name_${index}`"
                  class="md-layout-item md-size-50 md-large-size-75 text"
                >
                  {{ getSettingName(name) }}
                </div>

                <div
                  v-if="!isEdit"
                  :key="`guild-setting-value_${index}`"
                  class="md-layout-item md-size-50 md-large-size-25 text"
                >
                  {{ value }}
                </div>

                <ValidationProvider
                  v-else
                  :key="`guild-setting-value-edit_${index}`"
                  v-slot="{ errors, reset }"
                  class="md-layout-item md-size-50 md-large-size-25"
                  mode="passive"
                  tag="div"
                  rules="required"
                >
                  <md-field :class="{ error: errors.length }">
                    <ValidationError :errors="errors" />
                    <md-input
                      v-model="guildSettings[name]"
                      :disabled="isLoaderVisible"
                      @focus="reset"
                    />
                  </md-field>
                </ValidationProvider>
              </template>
            </div>

            <div class="buttons-wrap">
              <md-button
                v-if="!isEdit"
                class="md-raised md-primary"
                :disabled="isLoaderVisible"
                @click="isEdit = true"
              >
                Изменить
              </md-button>

              <template v-else>
                <md-button
                  class="md-raised md-success"
                  :disabled="isLoaderVisible"
                  @click="updateSettings"
                >
                  Сохранить
                </md-button>

                <md-button class="md-raised md-danger" @click="cancel">
                  Отмена
                </md-button>
              </template>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      guildSettings: null,
      isEdit: false,
    };
  },

  computed: {
    ...mapState(["isLoaderVisible"]),
    ...mapState("guild", ["guildData"]),
  },

  async mounted() {
    this.showLoader(true);
    await this.getGuildData();
    this.guildSettings = JSON.parse(JSON.stringify(this.guildData));
    this.showLoader(false);
  },

  methods: {
    ...mapActions("guild", ["getGuildData", "setGuildData"]),
    ...mapMutations({
      showLoader: "SET_SHOW_LOADER",
    }),

    cancel() {
      this.guildSettings = JSON.parse(JSON.stringify(this.guildData));
      this.isEdit = false;
    },

    getSettingName(name) {
      switch (name) {
        case "increasedPrecentEditingPrice":
          return "Процент увеличения стоимости следующего изменения";
        case "pricePerOneChange":
          return "Стоимость одного изменения";
        case "changeMembersStep":
          return "Шаг изменения макс.количества членов гильдии";
        case "maxMembers":
          return "Макс.количество членов гильдии";
        case "creationPrice":
          return "Стоимость создания гильдии";
        default:
          return "";
      }
    },

    async updateSettings() {
      const isValid = await this.$refs.updateGuildSettingsForm.validate();
      if (!isValid) {
        return;
      }

      this.showLoader(true);

      try {
        await this.setGuildData(this.guildSettings);
        await this.getGuildData();
        this.guildSettings = JSON.parse(JSON.stringify(this.guildData));
        this.isEdit = false;
      } catch (error) {
        console.error(error);
      }

      this.showLoader(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.md-layout {
  position: relative;
  z-index: 2;
}

.text {
  padding: 23px 20px 7px;
  margin: 4px 0 24px;
}

.md-success {
  margin-right: 10px;
}
</style>
